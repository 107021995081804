import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Classes from "./location.module.css";

const Location = ({ match }) => {
  const [inputs, setInputs] = useState({});
  const [select, setSelect] = useState("In transit");
  const [textArea, setTextArea] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [oldLocation, setOldLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingLoc, setLoadingLoc] = useState(false);
  const mapIDHandler = useRef();

  const getAllLocation = async () => {
    try {
      if (loadingLoc) {
        return null;
      }
      setLoadingLoc(true);
      const fetched = await fetch(
        `https://api.unisondelivery.com/api/v1/transport/location/${match.params.trackingNumber}`,
        // `http://localhost:9009/api/v1/transport/location/${match.params.trackingNumber}`,
        {
          method: "GET",
          header: new Headers({
            "Content-Type": "application/json",
          }),
        }
      );
      const parsedJSON = await fetched.json();

      setOldLocation(parsedJSON.data);
      setPlaceholder(parsedJSON.data[parsedJSON.data.length - 1].placeholder);
      mapIDHandler.current.insertAdjacentHTML(
        "beforeend",
        parsedJSON.data[parsedJSON.data.length - 1].mapHtml
      );
      setLoadingLoc(false);
    } catch (error) {
      console.log(error);
      setLoadingLoc(true);
    }
  };

  const onSubmitFormHandler = async (e) => {
    e.preventDefault();

    // try {
    if (loading) {
      return null;
    }
    setLoading(true);

    Axios.post(
      `https://api.unisondelivery.com/api/v1/transport/location/${match.params.trackingNumber}/${match.params.product}`,
      // `http://localhost:9009/api/v1/transport/location/${match.params.trackingNumber}/${match.params.product}`,
      {
        date: inputs.date,
        placeholder: select,
        location: inputs.location,
        price: inputs.price,
        mapHtml: textArea,
      }
    )
      .then(({ data }) => {
        setLoading(false);
        toast.success(`location for ${match.params.product} created`);
        setInputs({});
        setTextArea("");
        getAllLocation();
        //  alert(data.data._id)
        //  setTimeout(()=>this.props.history.push('/'),1000)
      })
      .catch((e) => {
        setLoading(true);
        let { response } = e;
        if (response.data) return toast.error(response.data.errorMsg);
        toast.warning("this is an error");
      });
  };

  useEffect(() => {
    getAllLocation();
  }, []);

  const setEachClicked = (e, t) => {
    setPlaceholder(oldLocation[e].placeholder);
    mapIDHandler.current.innerHTML = "";
    mapIDHandler.current.insertAdjacentHTML(
      "beforeend",
      oldLocation[e].mapHtml
    );
  };

  // console.log(oldLocation)

  const mappingLocations = oldLocation.map((v, i) => {
    return (
      <div style={{ cursor: "pointer", marginTop: "10px", display: "flex" }}>
        <div>{v.date}</div>
        <div
          style={{
            color: "#ff5e14",
            margin: "10px 0px 0px 9px",
            width: "5px",
            height: "5px",
            borderRadius: "50%",
            backgroundColor: "#ff5e14",
          }}
        ></div>
        <div
          onClick={setEachClicked.bind(this, i)}
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            margin: "4px 0px 0px 6px",
            color: "#222",
          }}
        >
          {v.location}
        </div>
      </div>
    );
  });

  // const mappingLocations= "oldLocation.map((v,i) => {"

  return (
    <div className={Classes.LocationCover}>
      <ToastContainer hideProgressBar />
      <center>
        <h2 style={{ marginBottom: "20px" }}>
          Add package current location and Price
        </h2>
      </center>

      <div
        className="Track_PackageMainCover"
        style={{ marginTop: "20px", width: " 100%" }}
      >
        <div
          className="Track_PackageCover"
          style={{
            backgroundColor: "#fff",
            boxSizing: "border-box",
            filter: "drop-shadow(0px 0px 3px #666)",
            width: "100%",
            borderRadius: "9px",
            padding: "1em",
            marginBottom: " 20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="TrackingIDSection"
              style={{ fontWeight: "bold", color: "#000" }}
            >
              ID <span>{match.params.trackingNumber}</span>{" "}
            </div>
            <div
              style={{
                padding: "3px 13px",
                fontSize: "12px",
                borderRadius: "20px",
                color: "#fff",
                boxSizing: "border-box",
                backgroundColor: "#ff5e14",
              }}
            >
              {placeholder}
            </div>
          </div>
          <div
            id="productsMainID"
            style={{ textTransform: "capitalize", color: "#333" }}
          >
            {match.params.product}
          </div>

          {mappingLocations}
        </div>

        <div
          style={{ width: "max-content", margin: "auto" }}
          ref={mapIDHandler}
        ></div>
      </div>

      <form onSubmit={onSubmitFormHandler}>
        <div className={Classes.inputSection}>
          <label>Date</label>
          <input
            className={Classes.input}
            value={inputs?.date}
            onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
            required
            type="date"
          />
        </div>
        <div className={Classes.inputSection}>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label>Location</label>{" "}
            <a
              href="https://google-map-generator.com/"
              target="_blank"
              className={Classes.getMapHtml}
            >
              Get HTML Map and Location
            </a>{" "}
          </div>
          <input
            className={Classes.input}
            value={inputs?.location}
            onChange={(e) => setInputs({ ...inputs, location: e.target.value })}
            required
            type="text"
          />
        </div>
        <div className={Classes.inputSection}>
          <label>Price</label>
          <input
            className={Classes.input}
            value={inputs?.price}
            onChange={(e) => setInputs({ ...inputs, price: e.target.value })}
            required
            type="text"
          />
        </div>
        <div className={Classes.inputSection}>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label>HTML Map</label>{" "}
            <a
              href="https://google-map-generator.com/"
              target="_blank"
              className={Classes.getMapHtml}
            >
              Get HTML Map and Location
            </a>{" "}
          </div>
          <textarea
            className={Classes.textarea}
            onChange={(e) => setTextArea(e.target.value)}
            rows="4"
            placeholder="paste the HTML code for google map locations"
            required
          />
        </div>

        <div style={{ margin: "20px 0px" }} className={Classes.inputSection}>
          <lable>Lable</lable>
          <select
            className={Classes.select}
            onChange={(e) => setSelect(e.target.value)}
          >
            <option>In transit</option>
            <option>Complete</option>
            <option>Idle timeout</option>
            <option>No connection</option>
          </select>
        </div>
        <button className={Classes.button}>{loading ? "..." : "Submit"}</button>
      </form>
    </div>
  );
};

export default Location;
