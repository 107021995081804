import Axios from "axios";
import React from "react";
import { toast, ToastContainer } from "react-toastify";

class CreateConsignment extends React.Component {
  state = {
    consignment: {},
    url: "",
  };

  handleConsignmentEdit = (e) => {
    toast.success("creation in process...");
    e.preventDefault();
     Axios.post('https://api.unisondelivery.com/api/v1/consignment/create', {
    // Axios.post("http://localhost:9009/api/v1/consignment/create", {
      ...this.state.consignment,
    })
      .then(({ data }) => {
        toast.success("consignment created");
        alert(data.data._id);
        setTimeout(() => this.props.history.push("/"), 1000);
      })
      .catch((e) => {
        let { response } = e;
        if (response.data) return toast.error(response.data.errorMsg);
        toast.warning("this is an error");
      });
  };

  render() {
    return (
      <div>
        <ToastContainer hideProgressBar />
        <div>
          {/* if the url is invalid  */}
          {/* if it is valid */}
          <br />
          <br />
          <br />
          <h3 style={{ textAlign: "center" }}>Edit consignment</h3>
          <form
            method="PUT"
            onSubmit={this.handleConsignmentEdit}
            className="form"
          >

            
            <div>
              <label style={{ marginLeft: "4%" }}>Parcel Shipped</label>
              <input
                // style={{marginTop: "5px"}}
                type="date"
                placeholder="parcel_shipped"
                value={this.state.consignment?.parcel_shipped}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_shipped: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>Parcel Payment Mode</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel payment mode"
                value={this.state.consignment?.parcel_payment_mode}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_payment_mode: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>Parcel Status</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="Parcel Status"
                value={this.state.consignment?.parcel_status}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_status: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>parcel_weight</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel_weight"
                value={this.state.consignment?.parcel_weight}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_weight: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>parcel_origin</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel_origin"
                value={this.state.consignment?.parcel_origin}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_origin: e.target.value,
                    },
                  })
                }
              />
            </div>


            <div>
              <label style={{ marginLeft: "4%" }}>Parcel_final_destination</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="Parcel_final_destination"
                value={this.state.consignment?.Parcel_final_destination}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      Parcel_final_destination: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>parcel_shipping_type</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel_shipping_type"
                value={this.state.consignment?.parcel_shipping_type}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_shipping_type: e.target.value,
                    },
                  })
                }
              />
            </div>
            
            
            
            <div>
              <label style={{ marginLeft: "4%" }}>parcel_shipping_mode </label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel_shipping_mode "
                value={this.state.consignment?.parcel_shipping_mode}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_shipping_mode: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>parcel_shipping_date</label>
              <input
                type="date"
                placeholder="parcel_shipping_date"
                value={this.state.consignment?.parcel_shipping_date}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_shipping_date: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>expected_delivery_date</label>
              <input
                type="date"
                placeholder="expected_delivery_date"
                value={this.state.consignment?.expected_delivery_date}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      expected_delivery_date: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>currently_in</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      currently_in: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.currently_in}
                type="text"
                placeholder="currently_in"
              />
            </div>
        
            <div>
              <label style={{ marginLeft: "4%" }}>sender_name</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      sender_name: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.sender_name}
                type="text"
                placeholder="sender_name"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>sender_address</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      sender_address: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.sender_address}
                type="text"
                placeholder="sender_address"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>sender_email</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      sender_email: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.sender_email}
                type="text"
                placeholder="sender_email"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>sender_phone</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      sender_phone: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.sender_phone}
                type="text"
                placeholder="sender_phone"
              />
            </div>
              {/* <label style={{ marginLeft: "4%" }}>is_on_hold</label> */}
            {/* <div>
              <input
                id="held"
                type="checkbox"
                style={{
                  marginLeft: "50px",
                }}
                checked={this.state.consignment?.is_on_hold}
                onChange={() =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      is_on_hold: !this.state.consignment.is_on_hold,
                    },
                  })
                }
              />
              <label htmlFor="held">Held by custom</label>
            </div> */}
            <div>
              <label style={{ marginLeft: "4%" }}>receiver_name</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      receiver_name: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.receiver_name}
                type="text"
                placeholder="receiver_name"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>receiver_address</label>
              <input
                value={this.state.consignment?.receiver_address}
                type="text"
                placeholder="receiver_address"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      receiver_address: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>receiver_email</label>
              <input
                value={this.state.consignment?.receiver_email}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      receiver_email: e.target.value,
                    },
                  })
                }
                type="text"
                placeholder="receiver_email"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>receiver_phone</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      receiver_phone: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.receiver_phone}
                type="text"
                placeholder="receiver_phone"
              />
            </div>



{/* 

            <div>
              <label style={{ marginLeft: "4%" }}>Weight</label>
              <input
                type="text"
                placeholder="weight"
                value={this.state.consignment?.weight}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      weight: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>destination</label>
              <input
                value={this.state.consignment?.destination}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      destination: e.target.value,
                    },
                  })
                }
                type="text"
                placeholder="destination"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>carrier</label>
              <input
                value={this.state.consignment?.carrier}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      carrier: e.target.value,
                    },
                  })
                }
                type="text"
                placeholder="carrier"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>previous country </label>
              <input
                value={this.state.consignment?.type_of_shipment}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      type_of_shipment: e.target.value,
                    },
                  })
                }
                type="text"
                placeholder="previous country "
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>shipment_mode</label>

              <input
                value={this.state.consignment?.shipment_mode}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      shipment_mode: e.target.value,
                    },
                  })
                }
                type="text"
                placeholder="shipment mode"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>carrier_reference_no</label>

              <input
                value={this.state.consignment?.carrier_reference_no}
                type="text"
                placeholder="carrier reference no"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      carrier_reference_no: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>consignment content</label>

              <input
                value={this.state.consignment?.products}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      products: e.target.value,
                    },
                  })
                }
                type="text"
                placeholder="consignment content"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>sender email</label>

              <input
                value={this.state.consignment?.qty}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      qty: e.target.value,
                    },
                  })
                }
                type="number"
                placeholder="sender email"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>payment_mode</label>

              <input
                value={this.state.consignment?.payment_mode}
                type="text"
                placeholder="payment_mode"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      payment_mode: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>receiver email </label>

              <input
                value={this.state.consignment?.total_freight}
                type="text"
                placeholder="receiver email "
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      total_freight: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>expected delivery date</label>

              <input
                value={this.state.consignment?.expected_delivery_date}
                type="date"
                placeholder="expected delivery date"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      expected_delivery_date: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>departure date remarks</label>

              <input
                value={this.state.consignment?.departure_date_remarks}
                type="text"
                placeholder="departure date remarks"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      departure_date_remarks: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>pick up date</label>

              <input
                value={this.state.consignment?.pick_up_date}
                type="text"
                placeholder="pick up date"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      pick_up_date: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>pick up date remark</label>

              <input
                value={this.state.consignment?.pick_up_date_remark}
                type="text"
                placeholder="pick up date remark"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      pick_up_date_remark: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>comments</label>

              <input
                value={this.state.consignment?.comments}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      comments: e.target.value,
                    },
                  })
                }
                type="text"
                placeholder="comments"
              />
            </div> */}








            <button
              className="btn btn-warning"
              style={{ width: "95%", margin: "0 auto" }}
            >
              Submit consignment
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateConsignment;
