import React, { Fragment } from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./App.css";
import Navbar from "./components/nav";
import HOMEPAGE from "./pages";
import CreateConsignment from "./pages/create";
import EditConsignment from "./pages/edit";
import Location from "./pages/location/location";
import Login from './pages/login/index';
import ChangeP from './pages/login/changePass';
import Quote from "./pages/quote/quote";


class MainApplication extends React.Component {
  state = {
    auth: false,
    pass: "",
    cpass: "",
  };

  handleLogin = (e) => {
    e.preventDefault();
    if (
      this.state?.pass.toLowerCase() === "root" &&
      this.state?.cpass.toLowerCase() === "toor"
    )
      return this.setState((prevState) => ({ auth: true }));
      //  this.setState((prevState) => ({ auth: true }));
    toast.error("authentication failed");
  };
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          {/* {this.state.auth && ( */}
            <div>
              {sessionStorage.getItem('auth') && <Navbar />}
              {! sessionStorage.getItem('auth') && <Redirect from="/" to={'/login'}  exact/>}
              {sessionStorage.getItem('auth') && <><Route exact path="/" component={HOMEPAGE} />
              <Route exact path="/create" component={CreateConsignment} />
              <Route exact path="/edit/:id" component={EditConsignment} />
              <Route exact path="/all-quote" component={Quote} />
              <Route exact path="/change-password" component={ChangeP} />
              <Route exact path="/add-package-location/:product/:trackingNumber" component={Location} />  </>}
              {sessionStorage.getItem('auth') && <Redirect from="/login" to={'/'}  exact/>}
              <Route exact path={'/login'} component={Login}/>

            </div>
          {/* )} */}

          {/* show form */}
          {this.state.auth && (<form onSubmit={this.handleLogin}>
            <input
              value={this.state.pass}
              type="text"
              required
              placeholder="enter password"
              onChange={e=>this.setState({...this.state,pass: e.target.value})}
            />
            <input
              value={this.state.cpass}
              type="text"
              required
              placeholder="confirm password"
              onChange={e=>this.setState({...this.state,cpass: e.target.value})}


            />
            <input type="submit" value="Authenticate" />
            <ToastContainer hideProgressBar />
          </form>)}
        </BrowserRouter>
      </Fragment>
    );
  }
}

export default MainApplication;
