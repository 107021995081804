import React, { useRef } from 'react'
import './index.css'
export default function Index() {

    const username = useRef()
    const password = useRef()
    const btn = useRef()
    const HandleLogin = (e) => {
        e.preventDefault()
        btn.current.textContent = 'Please wait...';
        fetch('https://api.unisondelivery.com/admin/login', {
            method: 'post',
            headers: new Headers({
                'content-type': 'application/json'
            }),
            body: JSON.stringify({
                username: username.current.value?.toLowerCase(),
                password: password.current.value?.toLowerCase()
            })
        }).then(res=>res.json())
        .then(res=>{
            if(res.error){
                alert(res.msg);
        btn.current.textContent = 'Login';
            }else{
                sessionStorage.setItem('auth', true)
                window.location.replace('/')
            }
          

        })
        .catch(e=>{
            alert('please check your internet connection')
            btn.current.textContent = 'Login';
        })

    }

    return (
        <div className='wrapper'>
            <form className='form_' onSubmit={HandleLogin}>
                <div className='img'>
                    <img style={{width: '100px'}} alt='logo' src='https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png'/>
                </div>
                <section>
                    <input placeholder='username' type='text' required ref={username}/>
                    <input placeholder='Password' type='password' required ref={password}/>
                    <button className='btn' style={{margin: '0 auto'}} ref={btn}>Login</button>
                </section>
            </form>
        </div>
    )
}
