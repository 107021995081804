import React, { useEffect, useState } from "react";
import Classes from "./quote.module.css";

const Quote = () => {
  const [allQuotes, setAllQuotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const getQuotesHandler = async () => {
    setLoading(true);
    const getFetchQuote = await fetch("api/v1/quote", {
      // const getFetchQuote = await fetch("http://localhost:9009/api/v1/quote", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    const getJson = await getFetchQuote.json();
    setLoading(false);
    if (!getJson.error) {
      setAllQuotes(getJson.data);
      return;
    }
  };

  const MainDeleteHandler = async (id, e) => {
    const getFetchQuote = await fetch(
      `https://api.unisondelivery.com/api/v1/quote/${id}`,
      // `http://localhost:9009/api/v1/quote/${id}`,
      {
        method: "DELETE",
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    const getJson = await getFetchQuote.json();
    await getQuotesHandler();
  };

  const mappAllFetched = allQuotes.map((v) => {
    return (
      <EachSec
        key={v._id}
        name={v.name}
        DeleteHandler={MainDeleteHandler.bind(this, v._id)}
        email={v.email}
        phone={v.phone}
        delivery_city={v.delivery_city}
        city_of_departure={v.city_of_departure}
        weight={v.weight}
        height={v.height}
        width={v.width}
        length={v.length}
      />
    );
  });

  useEffect(() => {
    getQuotesHandler();
  }, []);
  return (
    <div className={Classes.QuoteCover}>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          Loading ...
        </div>
      ) : (
        <>
          {mappAllFetched.length > 0 ? (
            mappAllFetched
          ) : (
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              No Quotes
            </div>
          )}
        </>
      )}
    </div>
  );
};

const EachSec = ({
  name,
  email,
  phone,
  delivery_city,
  city_of_departure,
  weight,
  height,
  width,
  length,
  DeleteHandler,
}) => {
  return (
    <div className={Classes.quoteDesign}>
      <div onClick={DeleteHandler} className={Classes.Delete}>
        Delete
      </div>
      <div className={Classes.eachquoteDesign}>
        <div>
          <label>Name</label>
          <div className={Classes.inputSection}>{name}</div>
        </div>
        <div>
          <label>Email</label>
          <div className={Classes.inputSection}>{email}</div>
        </div>
      </div>
      <div className={Classes.eachquoteDesign}>
        <div>
          <label>Phone</label>
          <div className={Classes.inputSection}>{phone}</div>
        </div>
        <div>
          <label>Delivery City</label>
          <div className={Classes.inputSection}>{delivery_city}</div>
        </div>
      </div>

      <div className={Classes.eachquoteDesign}>
        <div>
          <label>City Of Departure</label>
          <div className={Classes.inputSection}>{city_of_departure}</div>
        </div>
        <div>
          <label>Weight</label>
          <div className={Classes.inputSection}>{weight}</div>
        </div>
      </div>

      <div className={Classes.eachquoteDesign}>
        <div>
          <label>Height</label>
          <div className={Classes.inputSection}>{height}</div>
        </div>
        <div>
          <label>Width</label>
          <div className={Classes.inputSection}>{width}</div>
        </div>
      </div>

      <div className={Classes.eachquoteDesign}>
        <div>
          <label>Length</label>
          <div className={Classes.inputSection}>{length}</div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
