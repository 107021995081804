import React, { useState } from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  const [ID, setID] = useState(props.details._id);



  console.log(props.details);

  React.useEffect(() => {
    let spread = props.details._id.split("");
    let trackingID = [
      spread[spread.length - 7],
      spread[spread.length - 6],
      spread[spread.length - 5],
      spread[spread.length - 4],
      spread[spread.length - 3],
      spread[spread.length - 2],
      spread[spread.length - 1],
    ];
    // console.log("trackingID.join( )", trackingID.join(" "));
    setID(trackingID.join(""))
  });
  return (
    <section className="card">
      <p>
        {" "}
        Tracking ID :{" "}
        <span style={{ color: "green", fontWeight: "bold" }}>
          {ID}
        </span>{" "}
      </p>
      <p>
        {" "}
        Weight :{" "}
        <span style={{ color: "green", fontWeight: "bold" }}>
          {props.details?.parcel_weight}
        </span>{" "}
      </p>
      <p>
        {" "}
        Receiver Name :{" "}
        <span style={{ color: "green", fontWeight: "bold" }}>
          {props.details?.receiver_name}
        </span>{" "}
      </p>
      <p>
        {" "}
        Payment Mode :{" "}
        <span style={{ color: "green", fontWeight: "bold" }}>
          {props.details?.parcel_payment_mode}
        </span>{" "}
      </p>
      <p>
        {" "}
        currently_in :{" "}
        <span style={{ color: "green", fontWeight: "bold" }}>
          {props.details?.currently_in}
        </span>{" "}
      </p>
      {/* <a
        href={`https://cabinlogistics.com/global/track.html?trackingID=${props.details._id}`}
      >
        <button className="btn">View on web</button>
      </a> */}
      <Link to={`/edit/${props.details._id}`}>
        <button className="btn btn-warning">Edit</button>
      </Link>
      <button
        className="btn btn-danger"
        onClick={props.delete.bind(this, props.details._id, props.index)}
      >
        Delete
      </button>
    </section>
  );
};

export default Card;
