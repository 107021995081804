import Axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { HomepageIsLoading } from "../pages";

class EditConsignment extends React.Component {
  componentDidMount() {
    let spread = this.props.match.params.id.split("");
    let trackingID = [
      spread[spread.length - 7],
      spread[spread.length - 6],
      spread[spread.length - 5],
      spread[spread.length - 4],
      spread[spread.length - 3],
      spread[spread.length - 2],
      spread[spread.length - 1],
    ];

    Axios.get(
      `https://api.unisondelivery.com/api/v1/consignment/view/${trackingID.join("")}`
      // `http://localhost:9009/api/v1/consignment/view/${trackingID.join("")}`
    ).then(({ data }) => {
      console.log(data?.data);
      this.setState((prevState) => ({
        consignment: data?.data,
        isLoaded: true,
        isLoading: false,
      }));
    });
  }

  state = {
    isLoading: true,
    isLoaded: false,
    consignment: {},
    url: "",
  };

  handleConsignmentEdit = (e) => {
    e.preventDefault();
    toast.success("updating..");
    Axios.put(
      `https://api.unisondelivery.com/api/v1/consignment/edit/${this.props.match.params.id}`,
      // `http://localhost:9009/api/v1/consignment/edit/${this.props.match.params.id}`,
      {
        ...this.state.consignment,
      }
    )
      .then(({ data }) => {
        console.log(data);
        // this.props.history.push('/')
        toast.success("update successful");
      })
      .catch((e) => {
        toast.error("ooops!!, an error occurred");
      });
  };

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <HomepageIsLoading />
        ) : (
          <div>
            {/* if the url is invalid  */}
            {/* if it is valid */}
            <br />
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ textAlign: "center" }}>Edit consignment</h3>
              <Link
                style={{ textDecoration: "none" }}
                to={`/add-package-location/${this.state.consignment?.products}/${this.state.consignment?.trackingNumber}`}
              >
                <div
                  style={{
                    cursor: "pointer",
                    marginTop: "-10px",
                    backgroundColor: "#c2232a",
                    padding: "1em",
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  Edit Location
                </div>
              </Link>
            </div>
            <form
              method="PUT"
              onSubmit={this.handleConsignmentEdit}
              className="form"
            >

              
            <div>
              <label style={{ marginLeft: "4%" }}>Parcel Shipped</label>
              <input
                // style={{marginTop: "5px"}}
                type="date"
                placeholder="parcel_shipped"
                value={this.state.consignment?.parcel_shipped}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_shipped: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>Parcel Payment Mode</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel payment mode"
                value={this.state.consignment?.parcel_payment_mode}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_payment_mode: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>Parcel Status</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="Parcel Status"
                value={this.state.consignment?.parcel_status}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_status: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>parcel_weight</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel_weight"
                value={this.state.consignment?.parcel_weight}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_weight: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>parcel_origin</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel_origin"
                value={this.state.consignment?.parcel_origin}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_origin: e.target.value,
                    },
                  })
                }
              />
            </div>


            <div>
              <label style={{ marginLeft: "4%" }}>Parcel_final_destination</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="Parcel_final_destination"
                value={this.state.consignment?.Parcel_final_destination}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      Parcel_final_destination: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>parcel_shipping_type</label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel_shipping_type"
                value={this.state.consignment?.parcel_shipping_type}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_shipping_type: e.target.value,
                    },
                  })
                }
              />
            </div>
            
            
            
            <div>
              <label style={{ marginLeft: "4%" }}>parcel_shipping_mode </label>
              <input
                // style={{marginTop: "5px"}}
                type="text"
                placeholder="parcel_shipping_mode "
                value={this.state.consignment?.parcel_shipping_mode}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_shipping_mode: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div>
              <label style={{ marginLeft: "4%" }}>parcel_shipping_date</label>
              <input
                type="date"
                placeholder="parcel_shipping_date"
                value={this.state.consignment?.parcel_shipping_date}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      parcel_shipping_date: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>expected_delivery_date</label>
              <input
                type="date"
                placeholder="expected_delivery_date"
                value={this.state.consignment?.expected_delivery_date}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      expected_delivery_date: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>currently_in</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      currently_in: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.currently_in}
                type="text"
                placeholder="currently_in"
              />
            </div>
        
            <div>
              <label style={{ marginLeft: "4%" }}>sender_name</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      sender_name: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.sender_name}
                type="text"
                placeholder="sender_name"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>sender_address</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      sender_address: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.sender_address}
                type="text"
                placeholder="sender_address"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>sender_email</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      sender_email: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.sender_email}
                type="text"
                placeholder="sender_email"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>sender_phone</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      sender_phone: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.sender_phone}
                type="text"
                placeholder="sender_phone"
              />
            </div>
              {/* <label style={{ marginLeft: "4%" }}>is_on_hold</label> */}
            {/* <div>
              <input
                id="held"
                type="checkbox"
                style={{
                  marginLeft: "50px",
                }}
                checked={this.state.consignment?.is_on_hold}
                onChange={() =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      is_on_hold: !this.state.consignment.is_on_hold,
                    },
                  })
                }
              />
              <label htmlFor="held">Held by custom</label>
            </div> */}
            <div>
              <label style={{ marginLeft: "4%" }}>receiver_name</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      receiver_name: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.receiver_name}
                type="text"
                placeholder="receiver_name"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>receiver_address</label>
              <input
                value={this.state.consignment?.receiver_address}
                type="text"
                placeholder="receiver_address"
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      receiver_address: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>receiver_email</label>
              <input
                value={this.state.consignment?.receiver_email}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      receiver_email: e.target.value,
                    },
                  })
                }
                type="text"
                placeholder="receiver_email"
              />
            </div>
            <div>
              <label style={{ marginLeft: "4%" }}>receiver_phone</label>
              <input
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    consignment: {
                      ...this.state.consignment,
                      receiver_phone: e.target.value,
                    },
                  })
                }
                value={this.state.consignment?.receiver_phone}
                type="text"
                placeholder="receiver_phone"
              />
            </div>
              <button
                className="btn btn-warning"
                style={{ width: "95%", margin: "0 auto" }}
              >
                Update consignment
              </button>
            </form>
            <ToastContainer hideProgressBar />
          </div>
        )}
      </div>
    );
  }
}

export default EditConsignment;
