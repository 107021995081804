import React from 'react'
// import logo from '../logo.png'
import { Link } from 'react-router-dom'
import './nav.css'
const Navbar = props => {
    return(
        <header className='header-nav'>
            <nav>
                <div className='logo'>
                    <Link to='/'><img src={"https://topspeedbase.com/logo/63cef215c4bd2-1674506773.png"} alt={document?.title}  /></Link>
                </div>
                <ul>
                    <li style={{backgroundColor: "#c2232a", padding: "10px", borderRadius: "9px", fontWeight: "bold"}}>
                        <Link to='/create'>Create Consignment</Link>
                    </li>
                    <li>
                    <Link to="/all-quote">
                        quote
                        </Link>
                    </li>

                    <li>
                    <Link to="/change-password">
                        Access Control
                        </Link>
                    </li>
                    
                </ul>
            </nav>
        </header>
    )
}

export default Navbar;