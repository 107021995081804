import Axios from "axios";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "../components/card";
import loader from '../loader.gif';
class HOMEPAGE extends React.Component {
  // component mount
  componentDidMount() {
    Axios.get(`https://api.unisondelivery.com/api/v1/consignment/view`)
    // Axios.get(`http://localhost:9009/api/v1/consignment/view`)
      .then(({ data }) => {
        //SUCCESS
        this.setState(prevState=>({allConsignment:data.data,isLoading:false}))
        toast.success("Consignments are loaded", {
          position: "bottom-left",
          hideProgressBar: true,
        });
      })
      .catch((e) => {
        this.setState(prevState=>({isLoading:true}))
        //ERROR
        toast.error("oops!!, an error occurred", {
          position: "bottom-left",
          hideProgressBar: true,
        });
      });
  }

  state = {
    isLoadedIn: true,
    isLoading: !true,
    allConsignment: [],
  };


  handleDeleteRequest = async  (id,index)=> {
      let all = [...this.state.allConsignment];
      all.splice(index,1)
      this.setState(prevState=>({allConsignment: all}))
      toast.success('Delete in progress')
// main delete request 

    Axios.delete(`https://api.unisondelivery.com/api/v1/consignment/delete/${id}`)
    // Axios.delete(`http://localhost:9009/api/v1/consignment/delete/${id}`)
    .then(()=>{
        toast.success('Delete successful')
    })
    // ERROR: 
    .catch(({response})=>{
        if(response) return toast.warning(response.data.errorMsg,{
            position:'bottom-right'}
        );
        toast.warning('oops!!, an error occurred', {
            position:'bottom-right'
        })
    })
  }


  render() {

    return (
      <>
        {this.state.isLoadedIn && (
          <div>
            {/* if the user is logged in */}
            {this.state.isLoading ? (
              <HomepageIsLoading />
            ) : (
              <div>
                {this.state.allConsignment.length < 1 ? (
                  <div className="loading">You have no consignment</div>
                ) : (
                  <div>
                    {this.state.allConsignment.map((eachConsignment,eachConsignmentIndex)=>(
                        <Card details={eachConsignment} key={eachConsignment.id} delete={this.handleDeleteRequest} index={eachConsignmentIndex}/>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <ToastContainer newestOnTop hideProgressBar />
      </>
    );
  }
}

export default HOMEPAGE;


export const HomepageIsLoading = () => (
    <div className="loading">
      <img
        src={loader}
        alt="Loading...."
      />
    </div>
  );